<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{ $t("message.new_m", { m: $t("message.service") }) }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item
                      prop="name"
                      :label="$t('message.name')"
                      >
                        <el-input
                            :placeholder="$t('message.name')"
                            v-model="form.name"
                            size="large"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <el-col :span="12">
                      <el-form-item  :label="$t('message.dealType')" prop="deal_types">
                        <el-select class="w-100" v-model="form.deal_types" :placeholder="$t('message.dealType')" multiple>
                          <el-option :label="$t('message.commertial_cargo')" :value="'commercial'"></el-option>
                          <el-option :label="$t('message.parcel')" :value="'parcel'"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item :label="$t('message.serviceCategory')" prop="service_category_id">
                      <select-service-category 
                        v-model="form.service_category_id" 
                        :id="form.service_category_id" 
                        :size="'medium'">
                      </select-service-category> 
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item  :label="$t('message.show_cabinet')" prop="show_cabinet">
                      <el-checkbox v-model="form.show_cabinet" class="w-100" :label="$t('message.show_cabinet')" size="large" border></el-checkbox>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item :label="$t('message.manual_service')" prop="should_process">
                      <el-checkbox v-model="form.should_process" class="w-100" :label="$t('message.manual_service')" size="large" border></el-checkbox>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="20">
                    <div class="radio">
                      <el-col :span="16">
                        <el-form-item prop="calculate_by" :label="$t('message.calculate_by')">
                          <el-radio-group v-model="form.calculate_by">
                            <el-radio-button v-for="(c_type, index) in serviceCalculateTypes" :label="c_type.id">{{ c_type.name }}</el-radio-button>
                          </el-radio-group>  
                        </el-form-item>  
                      </el-col>

                      <el-col :span="8">
                        <el-form-item prop="auto_set" :label="$t('message.auto_set')">
                          <el-checkbox v-model="form.auto_set" :label="$t('message.auto_set')" border></el-checkbox>
                        </el-form-item>  
                      </el-col>

                      <el-col :span="24">
                        <el-form-item prop="calculation_type" :label="$t('message.calculation_type')">
                          <el-radio-group v-model="form.calculation_type">
                            <el-radio-button v-for="(c_type, index) in calculationTypes" :label="c_type.id">{{ c_type.name }}</el-radio-button>
                          </el-radio-group>  
                        </el-form-item>   
                      </el-col>
                    </div>
                    <div class="total_price">
                      <div class="price">
                        <el-col :span="12">
                          <el-form-item prop="service_sum" :label="getCalculationType">
                            <crm-money-input v-model="form.service_sum" :old="form.service_sum" :size="'large'"></crm-money-input>
                          </el-form-item>   
                        </el-col>
                        <el-col v-if="form.calculation_type != 4"  :span="12">
                          <el-form-item prop="currency_id" :label="$t('message.currency')">
                            <select-currency v-model="form.currency_id" :id="form.currency_id" :size="'large'"></select-currency> 
                          </el-form-item>     
                        </el-col>
                      </div>
                  </div>
                </el-row>
            </el-form>
        </div>
      </div>

      <price-list ref="priceList" :calculation_type="form.calculation_type"> </price-list>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectCurrency from '@/components/selects/select-currency';
import selectServiceCategory from '@/components/inventory/select-service-category';
import PriceList from "./price-list";

export default {
  mixins: [form, drawer],
  components: {
    selectCurrency, 
    selectServiceCategory,
    PriceList
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapGetters({
        rules: "services/rules",
        model: "services/model",
        columns: "services/columns",
        calculationTypes: "services/calculationTypes",
        serviceCalculateTypes: "services/serviceCalculateTypes",
    }),
    getCalculationType () {
      let calc = null;
      if(this.calculationTypes && this.calculationTypes.length > 0){
        calc = this.calculationTypes.find(el => el.id == this.form.calculation_type);
      }
      return calc ? calc.name : this.$t('message.price');
    },
  },
  methods: {
      ...mapActions({
          save: "services/store",
      }),
      submit(close = true) {
        this.form.priceList = this.$refs['priceList'].form.priceList;
        this.$refs["form"].validate((valid) => {
            if (valid) {
                this.loadingButton = true;
                this.save(this.form)
                    .then((res) => {
                        this.loadingButton = false;
                        this.$alert(res);
                        this.parent().listChanged();
                        if (close) this.close();
                    })
                    .catch((err) => {
                        this.loadingButton = false;
                        this.$alert(err);
                    });
            }
        });
      },
      allNumeric: function(num){
        if(num.match(/^-?\d+$/)){
          if (num > 100) this.form.percent = null;	
          else this.form.percent = num;
        }
        else this.form.percent = null;
      },
      afterLeave(){
        this.$store.commit('services/EMPTY_MODEL');
      } 
  },
};
</script>
<style>
.radio{
	width: 100%;
	margin-left: 12px;
}
.total_price{
	width: 100%;
	display: flex;
	flex-direction: row;
	margin: 30px 0 0 12px;
}
.price{
	width: 100%;
}
.percent{
	width: 50%;
}
.percent-slot{
	font-weight: bold;
	font-size: 17px;
	color: black;
}
</style>