<template>
    <div>
      <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.serviceCategory')" filterable clearable
        :size="size" class="w-100">
        <el-option v-for="(serviceCat,index) in serviceCategory" :key="'serviceCategory-' + index" :label="serviceCat.name" :value="serviceCat.id"></el-option>
      </el-select>
    </div>
  </template>
  <script>
    import {mapGetters, mapActions} from "vuex";
    export default {
      props: {
        size: {
          default: 'small'
        },
        placeholder: {
          default: null,
        },
        id:{
          default: null
        }
      },
      watch:{
        id:{
          handler: function() {
              this.selected = this.id
          },
          immediate: true
        }
      },
      data() {
        return {
          selected: null,
        }
      },
      mounted() {
        if (this.serviceCategory && this.serviceCategory.length === 0) this.updateInventory()
      },
      computed: {
        ...mapGetters({
          serviceCategory: 'serviceCategory/inventory'
        })
      },
      methods: {
        ...mapActions({
          updateInventory: 'serviceCategory/inventory'
        }),
        dispatch(e) {
          this.$emit('input', e)
          this.selected = e
        }
      },
    }
  
  </script>
  